import {Component, Input} from '@angular/core';
import {Article} from '../_model/article';

@Component({
	selector: 'app-view-article-modal',
	templateUrl: './view-article.modal.component.html'
})
export class ViewArticleModalComponent {

	@Input()
	public article: Article

}

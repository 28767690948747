import {Component, ContentChild, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormArray, FormGroup} from '@angular/forms';
import {ModularFormControl} from '../modular-form-control/modular-form-control';

@Component({
	selector: 'app-modular-form-dynamic-array',
	templateUrl: './modular-form-dynamic-array.component.html'
})
export class ModularFormDynamicArrayComponent extends ModularFormControl implements OnInit, OnDestroy {

	@ContentChild(TemplateRef) templateRef: TemplateRef<any>;

	@Input() public arrayControlName: string;
	@Input() public formGroupFactory: (obj: any) => FormGroup;

	formArray: FormArray<FormGroup>;
	private current: any[] = [];

	private readonly subscription = new Subscription();

	override ngOnInit(): void {
		this.update([]);

		const sub = this.formService.getControl(this.arrayControlName).valueChanges
			.subscribe((updated: any[]) => this.update(updated));
		this.subscription.add(sub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private update(updated: any[]): void {
		const controls: FormGroup[] = [];
		for (const obj of updated) {
			const index = this.current.indexOf(obj);
			if (index !== -1) {
				controls.push(this.formArray.controls[index]);
				continue;
			}

			controls.push(this.formGroupFactory(obj));
		}

		this.formArray = new FormArray(controls);
		this.formService.form.setControl(this.name, this.formArray);
		this.current = updated;
	}

}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Header} from './header';
import {Content} from '../model/content';
import {Observable, Subscription} from 'rxjs';

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, OnDestroy {
	private static readonly ANY_PERMISSION = 'ANY';

	@Input() headers: Header[];
	@Input() headerNamePrefix = '';
	@Input() id: string;
	@Input() addButtonColumn = false;
	@Input() nrOfButtonColumns = 1;
	@Input() removeStickyHeader = false;
	@Input() resetTriggersSearch = true;
	@Input() resetSearch = new Observable<void>();
	@Output() search = new EventEmitter<Content>();

	searchContent = {} as Content;

	private subscription = new Subscription();

	ngOnInit(): void {
		this.headers.forEach(header => {
			if (header.visible === undefined){
				header.visible = true;
			}
			this.searchContent[header.name] = '';
		});
		this.subscription.add(this.resetSearch.subscribe({
			next: () => {
				this.headers.forEach(header => this.searchContent[header.name] = '');
				if (this.resetTriggersSearch) {
					this.doEmitSearchContent();
				}
			}
		}));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	getPermissions(header: Header): string[] {
		return header.permissions ? header.permissions : [TableComponent.ANY_PERMISSION];
	}

	getNrOfButtonColumns(): number[] {
		return [...Array(this.nrOfButtonColumns).keys()]
	}

	hasSearchableHeaders(): boolean {
		return this.headers.filter(header => header.searchable).length !== 0;
	}

	updateSearch(headerName: string, searchValue: string): void {
		this.searchContent[headerName] = searchValue;
		this.doEmitSearchContent();
	}

	private doEmitSearchContent(): void {
		const toSearch = {} as Content;
		this.headers.forEach(header => {
			if (this.searchContent[header.name] !== '' && this.searchContent[header.name] !== undefined) {
				toSearch[header.name] = this.searchContent[header.name];
			}
		});
		this.search.next(toSearch);
	}
}

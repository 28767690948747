import {Directive, Input} from '@angular/core';
import {ModularFormControl} from '../modular-form-control/modular-form-control';

type LayoutStyle = 'stack' | 'stack-inline' | 'flex' | 'flex-inline';

@Directive()
export abstract class ModularFormControlInputWithLayout extends ModularFormControl {

	@Input() public layout: LayoutStyle = 'stack'
	@Input() public showTooltip = false;
	@Input() public hideLabel = false;

}

import {Component, OnInit} from '@angular/core';
import {combineLatest, map, Observable, startWith, Subject, Subscription, switchMap} from 'rxjs';
import {Page} from '../../shared/pagination/page';
import {Header} from '../../shared/table/header';
import {User} from '../_model/user';
import {UserService} from '../_service/user.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrMessageType} from '../../shared/toastr/toastr.message.type';
import {ToastrMessageService} from '../../shared/toastr/toastr.message.service';
import {TranslateService} from '@ngx-translate/core';
import {DropdownData} from '../../shared/dropdown-multiselect/dropdown-data';
import {ContentArray} from '../../shared/model/content-array';
import {DropdownDataFactory} from '../../shared/dropdown-multiselect/dropdown-data.factory';
import {UserFormModalComponent} from '../user-form-modal/user-form.modal.component';
import {UserInformationModalComponent} from '../user-information-modal/user-information.modal.component';
import {HttpResponse} from '@angular/common/http';
import {ModalService} from '../../shared/modal/modal.service';

@Component({
	selector: 'app-user-overview',
	templateUrl: './user-overview.component.html'
})
export class UserOverviewComponent implements OnInit {
	page$: Observable<Page<User>>;
	headers: Header[] = [
		{name: 'customer'},
		{name: 'user-name'},
		{name: 'first-name'},
		{name: 'last-name'},
		{name: 'creation-date'},
		{name: 'email'},
		{name: 'type'},
		{name: 'role'}
	];
	subscription = new Subscription();
	selectedFilterCriteria = {} as ContentArray;
	searchValue = '';
	filterDropdownData$: Observable<DropdownData[]>;
	private refresh$ = new Subject<void>();
	private currentPage$ = new Subject<number>();

	constructor(private userService: UserService,
				private ngbModal: NgbModal,
				private toastrMessageService: ToastrMessageService,
				private translateService: TranslateService,
				private dropdownDataFactory: DropdownDataFactory,
				private modalService: ModalService) {
	}

	ngOnInit(): void {
		this.page$ = combineLatest([this.currentPage$.pipe(startWith(1)), this.refresh$.pipe(startWith(''))])
			.pipe(
				switchMap(([currentPage]) => this.userService.search(currentPage - 1, this.selectedFilterCriteria, this.searchValue))
			);

		this.filterDropdownData$ = this.userService.getFilterCriteria().pipe(
			map(filterCriteria => filterCriteria.map(criteria => this.dropdownDataFactory.createDropdownData(criteria)))
		);
	}

	loadPage(page: number): void {
		this.currentPage$.next(page);
	}

	openViewModal(user: User): void {
		const modalRef = this.ngbModal.open(UserInformationModalComponent);
		modalRef.componentInstance.user = user;
	}

	openCreateModal(): void {
		this.modalService.openCreateModal(UserFormModalComponent, 'user').subscribe(() => this.refresh$.next());
	}

	openUpdateModal(user: User): void {
		this.modalService.openUpdateModal(UserFormModalComponent, user, 'user').subscribe(() => this.refresh$.next());
	}

	deleteUser(user: User): void {
		this.subscription.add(
			this.userService.delete(user.uuid)
				.subscribe(() => {
					this.toastrMessageService.show({
						type: ToastrMessageType.SUCCESS,
						messageHTML: `<i class="ti ti-trash me-2 fs-5"></i> ${this.translateService.instant('user.delete.success-message')}`
					});
					this.refresh$.next();
				}));
	}

	getExportFunction(): Observable<HttpResponse<Blob>> {
		return this.userService.export(this.selectedFilterCriteria, this.searchValue);
	}

	getConfirmationMessage(): string {
		return `<p>${this.translateService.instant('user.delete.confirmation-message.are-you-sure')}</p>`;
	}

	search(searchValue: any): void {
		this.searchValue = searchValue;
		this.loadPage(1);
	}

	filtersSelected(event: ContentArray): void {
		this.selectedFilterCriteria = {};
		Object.keys(event).forEach(key => this.selectedFilterCriteria[key] = event[key]);
		this.loadPage(1);
	}

	hasSearchCriteria(): boolean {
		return !!this.searchValue || Object.keys(this.selectedFilterCriteria).length > 0;
	}
}

import {Component, Input} from '@angular/core';
import {SelectOption} from '../_model/select-option';
import {ModularFormControlInputWithLayout} from '../modular-form-control-layout/modular-form-control-input-with-layout';

@Component({
	selector: 'app-modular-form-control-select',
	templateUrl: './modular-form-control-select.component.html'
})
export class ModularFormControlSelectComponent extends ModularFormControlInputWithLayout {

	@Input()
	public options: SelectOption[] = [];

	@Input()
	public compareWith: (o1: any, o2: any) => boolean = (o1, o2) => {
			if (o1?.uuid && o2?.uuid) {
				return o1.uuid === o2.uuid;
			}
			return o1 === o2;
		}

}

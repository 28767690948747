import {Component, OnChanges, OnDestroy, Self, SimpleChanges} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {finalize, Observable, Subscription} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UpdateForm} from '../../shared/modal/update.form';
import {Project} from '../_model/project';
import {ProjectService} from '../_service/project.service';
import {ModularFormsService} from '../../shared/modular-forms/modular-forms.service';
import {CreateOrUpdateProjectRequest} from '../_model/create-or-update-project.request';

@Component({
	selector: 'app-project-form-modal',
	templateUrl: './project-form-modal.component.html',
	providers: [ModularFormsService]
})
export class ProjectFormModalComponent implements OnChanges, OnDestroy, UpdateForm<Project> {

	project?: Partial<Project>;

	protected submitting = false;

	private subscription = new Subscription();

	constructor(private activeModal: NgbActiveModal,
				private projectService: ProjectService,
				@Self() protected formsService: ModularFormsService
	) {
		formsService.withI18nRoot('project.form');
		const form = formsService.form;

		form.addControl('name', new FormControl('', [Validators.required, Validators.maxLength(255)]))
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['project'] && this.project) {
			this.formsService.form.setValue(this.project);
		}
	}

	setContent(content: Project): void {
		this.project = content;
	}

	close(): void {
		if (!this.submitting) {
			this.activeModal.dismiss();
		}
	}

	submitForm(): void {
		if (this.submitting) {
			return;
		}
		this.submitting = true;

		this.subscription.add(this.doSubmit()
			.pipe(finalize(() => {
				this.submitting = false;
			}))
			.subscribe((project) => {
				this.activeModal.close(project);
			})
		);
	}

	doSubmit(): Observable<Project> {
		const request: CreateOrUpdateProjectRequest = this.formsService.form.value;

		if (this.project) {
			return this.projectService.update(this.project.uuid, request);
		} else {
			return this.projectService.create(request);
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	get i18nTitle(): string {
		return this.project ? 'project.update.modal.title' : 'project.create.modal.title';
	}

	get i18nSubmit(): string {
		return this.project ? 'general.save' : 'general.create';
	}
}


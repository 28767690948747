import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import {DropdownData} from './dropdown-data';

@Component({
	selector: 'app-dropdown-multiselect',
	templateUrl: './dropdown-multiselect.component.html',
	styleUrls: ['./dropdown-multiselect.component.css']
})
export class DropdownMultiselectComponent implements OnChanges, OnInit {

	@Input() providedData: DropdownData[];

	@Input() placeholder: string;

	@Input() selectAll = false;

	@Output() selection = new EventEmitter<string[]>();

	showDropdown = false;
	selectedValues: DropdownData[] = [];

	constructor(private elementRef: ElementRef) {
	}

	ngOnInit(): void {
		if (this.selectAll) {
			this.selectedValues = this.providedData;
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.selectedValues = this.providedData?.filter(item => item.isSelected) || (changes['providedData'].currentValue || []);
	}

	isSelected(dropdownItem: DropdownData): boolean {
		return this.selectedValues.indexOf(dropdownItem) >= 0;
	}

	selectOption(option: DropdownData): void {
		if (this.isSelected(option)) {
			if (option.value === 'all') {
				this.selectedValues = [];
			} else {
				this.selectedValues = this.selectedValues.filter(selectedValue => selectedValue.value !== option.value);
			}
		} else {
			if (option.value === 'all') {
				this.selectedValues = this.providedData;
			} else {
				this.selectedValues = [...this.selectedValues, option]
			}
		}
		this.selection.emit(this.selectedValues.filter(item => item.value !== 'all').map(value => value.value));
	}

	@HostListener('document:click', ['$event'])
	clickout(event: any): void {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.showDropdown = false;
		}
	}
}

import {SelectOption} from './select-option';
import {Program} from '../../../notices/model/program';
import {map, OperatorFunction} from 'rxjs';
import {GeoAreaMap} from '../../../article/_model/geo-area-map';
import {Role} from '../../../role/role';
import {Customer} from '../../../customer/customer';
import {ArticleDescriptor} from '../../../article/_model/article-descriptor';
import {Project} from 'src/app/project/_model/project';

export function mapPrograms(): OperatorFunction<Program[], SelectOption[]> {
	return map((programs: Program[]) => programs.map(program => ({
		value: program,
		label: program.name,
		id: program.uuid
	})));
}

export function mapGeoAreaTypes(): OperatorFunction<GeoAreaMap, SelectOption[]> {
	return map(geoAreaMap => Object.keys(geoAreaMap).map((type) => ({
		id: type,
		label: `geo-area.${type}.label`,
		translate: true,
		value: type
	})));
}

export function mapGeoAreas(): OperatorFunction<GeoAreaMap, { [key: string]: SelectOption[] }> {
	return map(geoAreaMap => {
		const result: { [key: string]: SelectOption[] } = {};
		for (const key in geoAreaMap) {
			result[key] = geoAreaMap[key].map(area => ({
				id: area,
				label: `geo-area.${key}.${area}.label`,
				translate: true,
				value: area
			}))
		}
		return result;
	});
}

export function mapRoles(): OperatorFunction<Role[], SelectOption[]> {
	return map(roles => roles.map(role => ({value: role, label: role.name, id: role.uuid})));
}

export function mapCustomers(): OperatorFunction<Customer[], SelectOption[]> {
	return map(customers => customers.map(customer => ({
		value: customer,
		label: customer.name,
		id: customer.uuid
	})));
}

export function mapArticles(articles: ArticleDescriptor[]): SelectOption[] {
	return articles.map(article => ({
		value: article.uuid,
		label: article.reference + (article.usualDesignation ? ` (${article.usualDesignation})` : ''),
		id: article.uuid
	}));
}

export function mapArticleDescriptors(): OperatorFunction<ArticleDescriptor[], SelectOption[]> {
	return map(articles => mapArticles(articles));
}

export function mapArticleDescriptorsWithoutUsualDesignation(): OperatorFunction<ArticleDescriptor[], SelectOption[]> {
	return map(articles => articles.map(article => ({
		value: article.uuid,
		label: article.reference,
		id: article.uuid
	})));
}

export function mapProjects(): OperatorFunction<Project[], SelectOption[]> {
	return map(projects => projects.map(project => ({value: project.uuid, label: project.name, id: project.uuid})));
}


export function mapStatusToSelectOption(status: string): SelectOption {
	return {
		value: status,
		label: 'production-catalogue.status.' + status,
		id: status,
		translate: true
	}
}

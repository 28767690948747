import {Component, OnDestroy} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationModalConfiguration} from './confirmation.modal.configuration';

@Component({
	selector: 'app-confirmation-modal',
	templateUrl: './confirmation.modal.component.html'
})
export class ConfirmationModalComponent implements OnDestroy {

	modalConfiguration: ConfirmationModalConfiguration;

	public submitting = false;

	constructor(public activeModal: NgbActiveModal) {
	}

	ngOnDestroy(): void {
		this.cancel();
	}

	cancel(): void {
		if (!this.submitting) {
			this.submitting = true;
			this.modalConfiguration.cancelAction.emit();
			this.activeModal.dismiss(false);
		}
	}

	confirm(): void {
		this.submitting = true;
		this.modalConfiguration.confirmAction.emit();
		this.activeModal.close(true);
	}

}

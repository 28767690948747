import {Injectable} from '@angular/core';

@Injectable()
export class DateProviderService {

	public currentDateString(): string {
		return new Date().toISOString().split('T')[0];
	}

	public currentTimeString(): string {
		return new Date().toISOString().split('T')[1].split('.')[0];
	}
}

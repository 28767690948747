<form [formGroup]="formService.form" (ngSubmit)="this.submit()" class="row flex-xxl-row-reverse">

	<div class="col-12 col-xxl-6">
		<app-modular-form-control-files name="actualFiles"/>

		<app-modular-form-dynamic-array arrayControlName="actualFiles" name="files"
										[formGroupFactory]="filesMetaGroupFactory">
			<ng-template let-index>
				<app-modular-form-control-input [name]="'files.' + index + '.fileName'" i18nPrefix="db.upload.db.form"/>

				<app-modular-form-control-input [name]="'files.' + index + '.hash'" i18nPrefix="db.upload.db.form"/>

				<app-modular-form-control-input [name]="'files.' + index + '.description'" i18nPrefix="db.upload.db.form"/>
			</ng-template>
		</app-modular-form-dynamic-array>
	</div>

	<div class="col-12 col-xxl-6">
		<app-db-upload-form/>
	</div>

	<div class="d-flex flex-row justify-content-end">
		<input id='upload-button' type="submit" class="btn btn-primary"
			   [value]="'db.upload.start-upload' | translate"
			   [disabled]="this.formService.form.invalid"/>
	</div>
</form>

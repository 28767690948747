import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {BaseUploadComponent} from '../../../shared/upload/base-upload.component';
import {ModularFormsService} from '../../../shared/modular-forms/modular-forms.service';
import {BehaviorSubject, filter, from, map, mergeMap, Observable, switchMap, tap} from 'rxjs';
import {UploadProgress} from '../../../shared/upload/upload.progress';
import {UploadProgressStatus} from '../../../shared/upload/upload.progress.status';
import {v4 as uuidv4} from 'uuid';
import {GeneralUploadProgress} from '../../../shared/upload/general.upload.progress';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {NoticeService} from '../../_service/notice.service';
import {UploadProgressComponent} from '../../../db/upload/upload-base/upload-progress/upload-progress.component';
import {SelectOption} from '../../../shared/modular-forms/_model/select-option';
import {CustomerService} from '../../../customer/customer.service';

@Component({
	selector: 'app-upload-notice-progress',
	templateUrl: './upload-notice-progress.component.html'
})
export class UploadNoticeProgressComponent extends BaseUploadComponent implements OnInit, OnDestroy {

	@Output() public resetAll = new EventEmitter();

	constructor(private formService: ModularFormsService,
				private noticeService: NoticeService,
				private customerService: CustomerService) {
		super();
	}

	ngOnInit(): void {
		this.upload();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	reset(): void {
		this.resetAll.emit();
	}

	private upload(): void {
		this.selectedFiles = this.formService.getControl('actualFiles').value;
		this.selectedFiles.forEach(file => this.progresses[file.name] = new BehaviorSubject<UploadProgress>(new UploadProgress(file.name, UploadProgressStatus.WAITING)));

		const uploadUuid = uuidv4();
		this.generalProgress.next(new GeneralUploadProgress(UploadProgressStatus.UPLOADING));

		let success = 0;

		this.subscription.add(from(Array.from(this.selectedFiles)).pipe(
			mergeMap(file => this.doUploadFile(file, uploadUuid), UploadProgressComponent.MAX_PARALELL_UPLOADS),
			tap(result => {
				if (result) {
					success++;
				}
			}),
			filter(() => success === this.selectedFiles.length),
			switchMap(() => this.createNotice(uploadUuid))
		).subscribe({
			next: () => this.generalProgress.next(new GeneralUploadProgress(UploadProgressStatus.DONE)),
			error: (err) => {
				this.cancelFilesInStateWaiting();
				let errors = [];
				if (err.error) {
					if (err.error.composite) {
						errors = err.error.errors;
					} else {
						errors.push(err.error);
					}
				}
				this.generalProgress.next(new GeneralUploadProgress(UploadProgressStatus.FAILED, errors));
			}
		}));
	}

	private createNotice(uploadUuid: string): Observable<any> {
		this.generalProgress.next(new GeneralUploadProgress(UploadProgressStatus.PROCESSING));

		const form = this.formService.form;
		const request = {...form.getRawValue()};
		delete request['actualFiles'];

		if (request['makeAvailableFor'] === 'CUSTOMERS') {
			request['customerUuids'] = request['availableCustomerUuids'].map((option: SelectOption) => option.id);
		} else if (request['makeAvailableFor'] === 'PROGRAMS') {
			request['programUuids'] = request['availableProgramUuids'].map((option: SelectOption) => option.id);
		} else if (request['makeAvailableFor'] === 'ALL_CUSTOMERS') {
			request['allCustomers'] = true;
		}

		delete request['makeAvailableFor'];
		delete request['availableCustomerUuids'];
		delete request['availableProgramUuids'];

		return this.noticeService.createNotice(request, uploadUuid);
	}

	private doUploadFile(file: File, uploadUuid: string): Observable<boolean> {
		return this.noticeService.uploadFile(file, uploadUuid)
			.pipe(
				tap((event: HttpEvent<any>) => this.handleHttpEvent(file, event)),
				filter((event: HttpEvent<any>) => event.type == HttpEventType.Response),
				map(() => true),
				tap({
					error: () => {
						const uploadProgress = new UploadProgress(file.name, UploadProgressStatus.FAILED);
						this.progresses[file.name].next(uploadProgress);
					}
				})
			);
	}

}

<div class="d-flex bd-highlight mb-3">
	<div><h1>{{ 'production-catalogue.form.title' | translate }}</h1></div>
	<div class="ms-auto mt-4">
		<div class="d-flex justify-content-end gap-3">
			<button id="close" type="button" class="btn btn-secondary" (click)="close()">{{ "general.go-back" | translate }}
			</button>
			<button id="save-catalogue" type="button" class="btn btn-primary" (click)="submitForm(form.form)">
				<span>{{ i18nSubmit | translate }}</span>
			</button>
		</div>
	</div>
</div>

<app-production-catalogue-form #form="productioncatalogueForm" [productionCatalogue]="productionCatalogue" [copy]="copy"></app-production-catalogue-form>

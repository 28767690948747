import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Page} from '../shared/pagination/page';
import {Customer} from './customer';
import {Observable} from 'rxjs';
import {CreateOrUpdateCustomerRequest} from './create-or-update-customer.request';

@Injectable()
export class CustomerService {

	readonly baseUrl = environment.backend_url + '/customer';

	constructor(private http: HttpClient) {
	}

	search(pageNumber: number, searchValue: string): Observable<Page<Customer>> {
		let params = new HttpParams();
		params = params.append('page', pageNumber);
		if (searchValue) {
			params = params.append('search.*', searchValue)
		}
		return this.http.get<Page<Customer>>(this.baseUrl + '/search', {params});
	}

	findAll(): Observable<Array<Customer>> {
		return this.http.get<Array<Customer>>(this.baseUrl);
	}

	export(searchValue: string): Observable<HttpResponse<Blob>> {
		let params = new HttpParams();
		if (searchValue) {
			params = params.append('search.*', searchValue)
		}
		return this.http.get(this.baseUrl + '/export', {observe: 'response', responseType: 'blob' , params});
	}

	create(customer: CreateOrUpdateCustomerRequest): Observable<Customer> {
		return this.http.post<Customer>(this.baseUrl, customer);
	}

	update(uuid: string, customer: CreateOrUpdateCustomerRequest): Observable<Customer> {
		return this.http.put<Customer>(`${this.baseUrl}/${uuid}`, customer);
	}

	delete(customerUuid: string): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${customerUuid}`);
	}

	findActiveSubscribedCustomersForArticle(articleUuid: string): Observable<Customer[]> {
		return this.http.get<Customer[]>(`${this.baseUrl}/find-for-article/${articleUuid}`);
	}
}

import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {BaseUploadComponent} from '../../../shared/upload/base-upload.component';
import {ModularFormsService} from '../../../shared/modular-forms/modular-forms.service';
import {FlightManagementSystemService} from '../../_service/flight-management-system.service';
import {BehaviorSubject, filter, map, tap} from 'rxjs';
import {UploadProgress} from '../../../shared/upload/upload.progress';
import {UploadProgressStatus} from '../../../shared/upload/upload.progress.status';
import {GeneralUploadProgress} from '../../../shared/upload/general.upload.progress';
import {HttpEvent, HttpEventType} from '@angular/common/http';

@Component({
	selector: 'app-fms-upload-progress',
	templateUrl: './fms-upload-progress.component.html'
})
export class FmsUploadProgressComponent extends BaseUploadComponent implements OnDestroy, OnInit{

	@Output() public resetAll = new EventEmitter();

	constructor(private formsService: ModularFormsService,
				private fmsService: FlightManagementSystemService) {
		super();
	}

	ngOnInit(): void {
		this.upload();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	reset(): void {
		this.resetAll.emit();
	}

	upload(): void {
		const file: File = this.formsService.getControl('files').value[0];

		this.selectedFiles = [file];
		this.progresses[file.name] = new BehaviorSubject<UploadProgress>(new UploadProgress(file.name, UploadProgressStatus.WAITING));
		this.generalProgress.next(new GeneralUploadProgress(UploadProgressStatus.UPLOADING));

		this.subscription.add(this.fmsService.upload(file)
			.pipe(
				tap((event: HttpEvent<any>) => this.handleHttpEvent(file, event)),
				filter((event: HttpEvent<any>) => event.type == HttpEventType.Response),
				map(() => true),
				tap({
					error: () => {
						const uploadProgress = new UploadProgress(file.name, UploadProgressStatus.FAILED);
						this.progresses[file.name].next(uploadProgress);
					}
				})
			).subscribe({
				next: () => this.generalProgress.next(new GeneralUploadProgress(UploadProgressStatus.DONE)),
				error: (err) => {
					this.cancelFilesInStateWaiting();
					let errors = [];
					if (err.error) {
						if (err.error.composite) {
							errors = err.error.errors;
						} else {
							errors.push(err.error);
						}
					}
					this.generalProgress.next(new GeneralUploadProgress(UploadProgressStatus.FAILED, errors));
				}
			}));
	}

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, map, Observable, startWith, Subject, Subscription, switchMap} from 'rxjs';
import {Page} from '../../shared/pagination/page';
import {Header} from '../../shared/table/header';
import {FlightDatabaseService} from '../_service/flight-database.service';
import {FlightDatabase} from '../_model/flight-database';
import {ToastrMessageType} from '../../shared/toastr/toastr.message.type';
import {TranslateService} from '@ngx-translate/core';
import {ToastrMessageService} from '../../shared/toastr/toastr.message.service';
import {DownloadFilesModalComponent} from '../download-files-modal-component/download-files.modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FlightDatabaseFileData} from '../_model/flight-database-file-data';
import {PermissionService} from '../../shared/permission/permission.service';
import {ContentArray} from '../../shared/model/content-array';
import {DropdownData} from '../../shared/dropdown-multiselect/dropdown-data';
import {DropdownDataFactory} from '../../shared/dropdown-multiselect/dropdown-data.factory';
import {DateProviderService} from '../../shared/date/date-provider.service';
import {HttpResponse} from '@angular/common/http';

@Component({
	selector: 'app-db-overview',
	templateUrl: './db-overview.component.html',
	providers: [DateProviderService]
})
export class DbOverviewComponent implements OnInit, OnDestroy {

	page$: Observable<Page<FlightDatabase>>;
	headers: Header[] = [
		{name: 'article-reference'},
		{name: 'database-name'},
		{name: 'usual-designation'},
		{name: 'airac-cycle'},
		{name: 'release-number'},
		{name: 'effective-date'},
		{name: 'end-of-validity-date', permissions: ['VIEW_END_VALIDITY_DATE']},
		{name: 'upload-time', permissions: ['VIEW_UPLOAD_TIME']}
	];
	subscription = new Subscription();
	selectedFilterCriteria = {} as ContentArray;
	filterDropdownData$: Observable<DropdownData[]>;
	private refresh$ = new Subject<void>();
	private currentPage$ = new Subject<number>();
	tab$ = new BehaviorSubject('ALL');
	searchValue: string;

	constructor(private flightDatabaseService: FlightDatabaseService,
				private translateService: TranslateService,
				private toastrMessageService: ToastrMessageService,
				private permissionService: PermissionService,
				private modalService: NgbModal,
				private dropdownDataFactory: DropdownDataFactory,
				private dateProviderService: DateProviderService) {
	}

	ngOnInit(): void {
		this.page$ = combineLatest([
			this.currentPage$.pipe(startWith(1)),
			this.refresh$.pipe(startWith('')),
			this.tab$
		]).pipe(switchMap(([currentPage]) => {
			return this.flightDatabaseService.search(currentPage - 1, this.selectedFilterCriteria, this.searchValue);
		}));

		this.filterDropdownData$ = this.flightDatabaseService.getFilterCriteria().pipe(
			map(filterCriteria => filterCriteria.map(criteria => this.dropdownDataFactory.createDropdownData(criteria)))
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	switchTab(tab: string): void {
		this.setTabFilter(tab);
		this.tab$.next(tab);
	}

	loadPage(page: number): void {
		this.currentPage$.next(page);
	}

	flagDatabaseAsError(uuid: string): void {
		this.subscription.add(
			this.flightDatabaseService.flagDatabaseAsError(uuid)
				.subscribe(() => {
					this.toastrMessageService.show({
						type: ToastrMessageType.SUCCESS,
						messageHTML: `<i class="ti ti-flag me-2 fs-5"></i> ${this.translateService.instant('db.overview.flag.success-message')}`
					});
					this.refresh$.next();
				}));
	}

	unflagDatabaseAsError(uuid: string): void {
		this.subscription.add(
			this.flightDatabaseService.unflagDatabaseAsError(uuid)
				.subscribe(() => {
					this.toastrMessageService.show({
						type: ToastrMessageType.SUCCESS,
						messageHTML: `<i class="ti ti-flag me-2 fs-5"></i> ${this.translateService.instant('db.unflag.success-message')}`
					});
					this.refresh$.next();
				}));
	}

	hasPermission(permission: string): Observable<boolean> {
		return this.permissionService.hasAtLeastOnePermission([permission]);
	}

	openDownloadModal(flightDatabaseUuid: string, files: Array<FlightDatabaseFileData>): void {
		const modalRef = this.modalService.open(DownloadFilesModalComponent, {windowClass: 'small-footer'});
		modalRef.componentInstance.setContext(flightDatabaseUuid, files);
	}

	deleteDatabase(uuid: string): void {
		this.subscription.add(
			this.flightDatabaseService.deleteDatabase(uuid)
				.subscribe(() => {
					this.toastrMessageService.show({
						type: ToastrMessageType.SUCCESS,
						messageHTML: `<i class="ti ti-trash me-2 fs-5"></i> ${this.translateService.instant('db.overview.delete.success-message')}`
					});
					this.refresh$.next();
				}));
	}

	filtersSelected(event: ContentArray): void {
		this.selectedFilterCriteria = {};
		this.setTabFilter(this.tab$.value);
		Object.keys(event).forEach(key => this.selectedFilterCriteria[key] = event[key]);
		this.loadPage(1);
	}

	getExportFunction(): Observable<HttpResponse<Blob>> {
		return this.flightDatabaseService.export(this.selectedFilterCriteria);
	}

	hasSearchCriteria(): boolean {
		return Object.keys(this.selectedFilterCriteria).length > 0;
	}

	search(searchValue: any): void {
		this.searchValue = searchValue;
		this.loadPage(1);
	}

	private setTabFilter(activeTab: string): void {
		const date = this.dateProviderService.currentDateString();
		delete this.selectedFilterCriteria['endValidityDate._gt'];
		delete this.selectedFilterCriteria['endValidityDate._lte'];
		if (activeTab == 'ACTIVE') {
			this.selectedFilterCriteria['endValidityDate._gt'] = [date];
		} else if (activeTab == 'INACTIVE') {
			this.selectedFilterCriteria['endValidityDate._lte'] = [date];
		}
	}
}

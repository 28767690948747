import {Component, Input, OnInit} from '@angular/core';
import {ModularFormControl} from '../../../shared/modular-forms/modular-form-control/modular-form-control';
import {SelectOption} from '../../../shared/modular-forms/_model/select-option';
import {ModularFormsService} from '../../../shared/modular-forms/modular-forms.service';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProductionCatalogue} from '../../_model/production-catalogue';

@Component({
	selector: 'app-production-catalogue-article-form',
	templateUrl: './article-form.component.html',
	styleUrls: ['./article-form.component.css']
})
export class ArticleFormComponent extends ModularFormControl implements OnInit {

	@Input() articles: SelectOption[];

	@Input() prodCat: ProductionCatalogue;

	@Input() readonly: boolean;

	constructor(private formsService: ModularFormsService) {
		super();
	}

	override ngOnInit(): void {
		super.ngOnInit();

		this.formService.form.get('selectedArticles').valueChanges.subscribe((selectedOptions) => {
			this.doAddArticles(selectedOptions);
		});

		if (this.prodCat) {
			this.prodCat.articles.forEach(articleData => {
				const selectOption = this.articles.filter(value => value.id === articleData.articleUuid)[0];
				this.addArticle(selectOption, articleData.productionDate);
			});
		}
	}

	private doAddArticles(selectedOptions: SelectOption[]): void {
		selectedOptions.forEach((el: SelectOption) => {
			if (!this.getArticleUuids().includes(el.id)) {
				this.addArticle(el, null);
			}
		})
	}

	getSelectedArticles(): SelectOption[] {
		return this.formService.getControl<FormArray>('selectedArticles').value;
	}

	private getArticleUuids(): string[] {
		const articlesArray = this.formService.getControl<FormArray>('articles');
		return articlesArray.controls.map((control: AbstractControl) => {
			return control.get('articleUuid').value;
		});
	}

	getArticleCount(): number {
		return this.getSelectedArticles()?.length;
	}

	addArticle(option: SelectOption, productionDate: any): void {
		const controls: { [key: string]: FormControl } = {};
		controls['articleUuid'] = new FormControl(option.id);
		controls['article'] = new FormControl(option.label, [Validators.required]);
		controls['article'].disable();
		controls['productionDate'] = new FormControl(productionDate, [Validators.required]);

		if ((this.prodCat && this.prodCat.status.status !== 'REDACTION_IN_PROGRESS') || this.readonly) {
			controls['articleUuid'].disable();
			controls['productionDate'].disable();
		}

		const group = new FormGroup(controls);
		this.formsService.getControl<FormArray>(this.name).push(group);
	}

	removeArticle(opt: SelectOption): void {
		const newVal = this.formService.getControl('selectedArticles').value as SelectOption[];
		const index = newVal.indexOf(opt);
		newVal.splice(index, 1);

		this.formService.getControl('selectedArticles').patchValue(newVal);
		this.formsService.getControl<FormArray>(this.name).removeAt(index);
	}

}

import {AbstractControl, ValidationErrors} from '@angular/forms';

export class FormValidators {

	public static requireNonEmptyArray(control: AbstractControl): ValidationErrors {
		if (!control.value || !Array.isArray(control.value) || control.value.length === 0) {
			return {required: true};
		}
		return null;
	}

}


import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild} from '@angular/core';
import {Customer} from '../customer';
import {ModularFormsService} from '../../shared/modular-forms/modular-forms.service';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProgramService} from '../../notices/_service/program.service';
import {Observable, Subscription} from 'rxjs';
import {SelectOption} from '../../shared/modular-forms/_model/select-option';
import {CustomerSubscriptionFormComponent} from './customer-subscription-form/customer-subscription-form.component';
import {mapPrograms} from '../../shared/modular-forms/_model/select-option.factory';

@Component({
	selector: 'app-customer-form',
	templateUrl: './customer-form.component.html'
})
export class CustomerFormComponent implements AfterViewInit, OnDestroy, OnChanges {

	@Input()
	public customer: Partial<Customer>;

	@Input()
	public readonly = false;

	@Output()
	public apply = new EventEmitter();

	programs$: Observable<SelectOption[]>;

	private subscription = new Subscription();

	@ViewChild('distributionSubscriptions')
	private distributionSubscriptions: CustomerSubscriptionFormComponent;

	@ViewChild('viewerSubscriptions')
	private viewerSubscriptions: CustomerSubscriptionFormComponent;

	constructor(protected formService: ModularFormsService,
				private programService: ProgramService) {

		this.programs$ = this.programService.findAll().pipe(mapPrograms());

		this.formService.withI18nRoot('customer.form');
		const form = this.formService.form;

		form.addControl('name', new FormControl('', [Validators.required, Validators.maxLength(100)]));
		form.addControl('icao', new FormControl('', [Validators.maxLength(255)]));
		form.addControl('distributionSubscriptions', new FormArray([]));
		form.addControl('viewerSubscriptions', new FormArray([]));
		form.addControl('programs', new FormControl([]));
		form.addControl('suspended', new FormControl(false));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	ngAfterViewInit(): void {
		if (this.customer) {
			this.customer.distributionSubscriptions.forEach(() => this.distributionSubscriptions.addSubscription());
			this.customer.viewerSubscriptions.forEach(() => this.viewerSubscriptions.addSubscription());

			this.formService.form.patchValue(this.customer);

			this.programs$
				.subscribe(programs => {
					const value = this.customer.programs
						.map(p => p.uuid)
						.map(puuid => programs.find(option => option.id === puuid))
					this.formService.getControl('programs').patchValue(value);
				});
		}
	}

	ngOnChanges(): void {
		this.setReadonlyStatus();
	}

	private setReadonlyStatus(): void {
		if (this.readonly) {
			this.formService.form.disable();
		} else {
			this.formService.form.enable();
		}
	}

	public get form(): FormGroup {
		return this.formService.form;
	}

	submitForm(): void {
		this.apply.emit(null);
	}

}

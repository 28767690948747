import {BehaviorSubject, Subscription} from 'rxjs';
import {UploadProgress} from './upload.progress';
import {GeneralUploadProgress} from './general.upload.progress';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {UploadProgressStatus} from './upload.progress.status';
import {HttpEvent, HttpEventType} from '@angular/common/http';

export class BaseUploadComponent {
	public static readonly MAX_PARALELL_UPLOADS = 3;
	public static readonly DATE_REGEX_PATTERN = '\\d{2}/\\d{2}/\\d{4}';
	public uploading = false;
	public selectedFiles: File[] = [];
	subscription = new Subscription();
	progresses: { [key: string]: BehaviorSubject<UploadProgress>; } = {};
	generalProgress = new BehaviorSubject<GeneralUploadProgress>(null);
	fileForm: FormGroup;

	getFileControls(): FormArray {
		return this.fileForm.controls['files'] as FormArray;
	}

	deleteFile(file: File): void {
		const index = this.selectedFiles.indexOf(file);
		this.selectedFiles.splice(index, 1);
		this.removeFileControl(index);
		this.progresses[file.name] = undefined;
	}

	getControl(name: string): FormControl<any> {
		return this.fileForm.controls[name] as FormControl<any>;
	}

	getProgresses(): BehaviorSubject<UploadProgress>[] {
		return Object.keys(this.progresses).map(key => this.progresses[key]);
	}

	resetForm(): void {
		this.fileForm.reset();
		const formArray = this.fileForm.get('files') as FormArray;
		while (formArray.length !== 0) {
			formArray.removeAt(0)
		}
		this.selectedFiles = [];
		this.uploading = false;
	}

	cancelFilesInStateWaiting(): void {
		this.getProgresses().forEach(subject => {
			const progress = subject.value;
			if (progress.status == UploadProgressStatus.WAITING) {
				this.progresses[progress.file].next(new UploadProgress(progress.file, UploadProgressStatus.CANCELLED));
			}
		});
	}

	handleHttpEvent(file: File, event: HttpEvent<any>): void {
		if (event.type === HttpEventType.UploadProgress) {
			const uploadProgress = new UploadProgress(file.name, UploadProgressStatus.UPLOADING, event.total, event.loaded);
			this.progresses[file.name].next(uploadProgress);
		} else if (event.type === HttpEventType.Response) {
			const uploadProgress = new UploadProgress(file.name, UploadProgressStatus.DONE);
			this.progresses[file.name].next(uploadProgress);
		}
	}

	private removeFileControl(index: number): void {
		this.getFileControls().removeAt(index);
	}
}

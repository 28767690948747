import {inject, Injectable} from '@angular/core';
import {map, Observable, of, ReplaySubject} from 'rxjs';
import {UserService} from '../../user/_service/user.service';
import {
	ActivatedRouteSnapshot,
	CanActivateChildFn,
	CanActivateFn,
	Router,
	RouterStateSnapshot,
	UrlTree
} from '@angular/router';

@Injectable()
export class PermissionService {

	static permissions = new ReplaySubject<string[]>(1);

	constructor(private userService: UserService,
				private router: Router) {
		this.refreshPermissions();
	}

	static getPermissions(): Observable<string[]> {
		return this.permissions;
	}

	hasAtLeastOnePermission(permissionsAllowed: string[]): Observable<boolean> {
		if (permissionsAllowed === undefined) {
			return of(false);
		} else if (permissionsAllowed.length === 1 && permissionsAllowed[0] === 'ANY') {
			return of(true);
		} else {
			return PermissionService.permissions.pipe(map(currentPermissions => permissionsAllowed.some(permissionAllowed => currentPermissions.indexOf(permissionAllowed) !== -1)));
		}
	}

	canActivateRoute(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
		const allowedPermissions = this.getAllowedPermissions(route);

		return this.hasAtLeastOnePermission(allowedPermissions).pipe(
			map(result => {
				if (!result) {
					return this.router.parseUrl('/no-access');
				}
				return result;
			}));
	}

	private refreshPermissions(): void {
		this.loadPermissions().subscribe(permissions => PermissionService.permissions.next(permissions));
	}

	private loadPermissions(): Observable<string[]> {
		return this.userService.getPermissionsForCurrentUser();
	}

	private getAllowedPermissions(route: ActivatedRouteSnapshot): any {
		const allowedPermissions = route.data['permissionsAllowed'];
		if (allowedPermissions) {
			return allowedPermissions;
		}
		throw new Error(`Route with no 'permissionsAllowed' array is defined on the route data object!`);
	}

}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	return inject(PermissionService).canActivateRoute(route);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	return inject(PermissionService).canActivateRoute(route);
}

import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Project} from '../_model/project';
import {ModularFormsService} from '../../shared/modular-forms/modular-forms.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
	selector: 'app-project-form',
	templateUrl: './project-form.component.html'
})
export class ProjectFormComponent implements OnChanges {

	@Input()
	public project?: Partial<Project>;

	@Input()
	public readonly = false;

	@Output()
	public apply = new EventEmitter();

	constructor(protected formsService: ModularFormsService) {
		formsService.withI18nRoot('project.form');
		const form = formsService.form;

		form.addControl('name', new FormControl('', [Validators.required, Validators.maxLength(255)]))
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['project'] && this.project) {
			this.formsService.form.patchValue(this.project);
		}

		if (changes['readonly']) {
			if (this.readonly) {
				this.formsService.form.disable();
			} else {
				this.formsService.form.enable();
			}
		}
	}

	public get form(): FormGroup {
		return this.formsService.form;
	}

	submitForm(): void {
		this.apply.emit(null);
	}

}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModularFormsService} from '../../../shared/modular-forms/modular-forms.service';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ProductionCatalogue} from '../../_model/production-catalogue';
import {Comment} from '../../_model/comment';
import {ModularFormControl} from '../../../shared/modular-forms/modular-form-control/modular-form-control';
import {UserService} from '../../../user/_service/user.service';
import {formatDate} from '@angular/common';

@Component({
	selector: 'app-comment-form',
	templateUrl: './comment-form.component.html',
	exportAs: 'commentForm'
})
export class CommentFormComponent extends ModularFormControl implements OnInit, OnDestroy {


	@Input() productionCatalogue?: ProductionCatalogue;

	isAddingComment$ = new BehaviorSubject(false);

	private subscription = new Subscription();

	constructor(protected formsService: ModularFormsService,
				private userService: UserService) {
		super();
		this.formsService.form.addControl('comments', new FormArray([]));
	}

	override ngOnInit(): void {
		super.ngOnInit();

		if (this.productionCatalogue) {
			this.setComments(this.productionCatalogue.comments);
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	save(): void {
		this.isAddingComment$.next(false);
	}

	cancel(): void {
		this.isAddingComment$.next(false);
		this.formsService.getControl<FormArray>(this.name).removeAt(this.getCommentCount() - 1);
	}

	getUserName(i: number): string {
		const userControl = this.formsService.getControl<FormArray>(this.name).controls[i]?.get('user');
		return userControl.value.firstName + ' ' + userControl.value.lastName;
	}

	addComment(): void {
		this.subscription.add(this.userService.getCurrentUser().subscribe(user => {
			const controls: { [key: string]: FormControl } = {};
			controls['comment'] = new FormControl('', [Validators.required, Validators.minLength(1)]);
			controls['date'] = new FormControl(formatDate(Date.now(),'dd/MM/yyyy HH:mm','en'));
			controls['user'] = new FormControl(user);
			controls['new'] = new FormControl(true);
			const group = new FormGroup(controls);
			this.formsService.getControl<FormArray>(this.name).push(group);
			this.isAddingComment$.next(true);
		}));
	}

	isValid(i: number): boolean {
		return this.formsService.getControl<FormArray>(this.name).controls[i].valid;
	}

	addExistingComment(comment: Comment): void {
		const controls: { [key: string]: FormControl } = {};
		controls['comment'] = new FormControl(comment.comment, [Validators.required, Validators.minLength(1)]);
		controls['date'] = new FormControl(comment.timestamp);
		controls['user'] = new FormControl(comment.user);
		const group = new FormGroup(controls);
		this.formsService.getControl<FormArray>(this.name).push(group);
	}

	getControls(): FormGroup[] {
		return this.formsService.getControl<FormArray>(this.name).controls as FormGroup[];
	}

	getCommentCount(): number {
		return this.getControls().length;
	}

	setComments(comments: Comment[]): void {
		comments.forEach(c => this.addExistingComment(c));
	}

}

import {Component, OnInit} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {combineLatest, filter, map, Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ActivationEnd, Data, Event, Router} from '@angular/router';
import {Meta} from '@angular/platform-browser';
import {environment} from '../environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
	showSupportingComponents$: Observable<boolean>

	constructor(public oidcSecurityService: OidcSecurityService,
				translateService: TranslateService,
				private router: Router,
				private meta: Meta) {
		translateService.setDefaultLang('en');
		translateService.use('en');
		meta.addTag({
			'http-Equiv': 'Content-Security-Policy',
			'content': 'connect-src ' + environment.analytics.domain_url + ' ' + environment.authentication.domain_url + ' ' + environment.backend_url + ' \'self\'; default-src \'self\'; img-src https://* \'self\' data:; style-src \'unsafe-inline\' \'self\''
		});
	}

	ngOnInit(): void {
		this.oidcSecurityService.checkAuth().subscribe();

		const isAuthenticated$ = this.oidcSecurityService.isAuthenticated$.pipe(map(result => result.isAuthenticated));
		const showComponents$ = this.router.events
			.pipe(
				filter((routeEvent: Event) => routeEvent instanceof ActivationEnd),
				map(event => event as ActivationEnd),
				map((event: ActivationEnd) => event.snapshot.data),
				map((data: Data) => data['showSupportingComponents'] !== undefined ? data['showSupportingComponents'] as boolean : true)
			);
		this.showSupportingComponents$ = combineLatest([isAuthenticated$, showComponents$])
			.pipe(
				map(([authenticated, show]) => authenticated && show)
			);
	}

}

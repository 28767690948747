<form [formGroup]="formService.form" (ngSubmit)="this.submit()" class="row flex-xxl-row-reverse">

	<div class="col-12 col-xxl-6">
		<app-modular-form-control-files name="actualFiles" accept="application/zip"/>

		<app-modular-form-dynamic-array arrayControlName="actualFiles" name="tsvFiles"
										[formGroupFactory]="viewerFilesMetaGroupFactory()">
			<ng-template let-index>
				<app-modular-form-control-input [name]="'tsvFiles.' + index + '.fileName'" i18nPrefix="db.upload.viewer.form"/>

				<app-modular-form-control-input [name]="'tsvFiles.' + index + '.hash'" i18nPrefix="db.upload.viewer.form"/>

				<ng-container *ngFor="let x of tsvFileControls(index); let i = index">
					<div class="border-top">
						<div class="ms-4 my-2">
							<app-modular-form-control-input
								[name]="'tsvFiles.' + index + '.tsvFiles.' + i + '.fileName'" i18nPrefix="db.upload.viewer.form"/>

							<app-modular-form-control-input
								[name]="'tsvFiles.' + index + '.tsvFiles.' + i + '.displayName'" i18nPrefix="db.upload.viewer.form"/>
						</div>
					</div>
				</ng-container>
			</ng-template>
		</app-modular-form-dynamic-array>
	</div>

	<div class="col-12 col-xxl-6">
		<app-db-upload-form/>
	</div>

	<div class="d-flex flex-row justify-content-end">
		<input id='upload-button' type="submit" class="btn btn-primary"
			   [value]="'db.upload.start-upload' | translate"
			   [disabled]="this.formService.form.invalid"/>
	</div>
</form>

import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrMessageService} from '../../shared/toastr/toastr.message.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrMessageType} from '../../shared/toastr/toastr.message.type';
import {NoticeService} from '../_service/notice.service';
import {BaseDownloadComponent} from '../../shared/download/base-download.component';
import {NoticeFile} from '../notice/notice-file';

@Component({
	selector: 'app-download-notice-file',
	templateUrl: './download-notice-file.component.html'
})
export class DownloadNoticeFileComponent extends BaseDownloadComponent<NoticeFile> {

	constructor(private noticeService: NoticeService,
				public override activeModal: NgbActiveModal,
				private toastrMessageService: ToastrMessageService,
				private translateService: TranslateService) {
		super(activeModal)
	}

	downloadFiles(fileUuid: string): void {
		if (this.isDownloading()) {
			return;
		}
		this.setDownloading(true);

		this.toastrMessageService.show({
			messageHTML: 'general.download-checksum-alert',
			sticky: true,
			type: ToastrMessageType.WARNING
		})

		this.noticeService.downloadFiles(this.entityUuid, fileUuid).subscribe(files => {
			const links = [] as HTMLAnchorElement[];
			files.forEach(storedFile => {
				const src = `data:application/octet-stream;base64,${storedFile.data}`;
				const link = document.createElement('a');
				link.href = src;
				link.download = storedFile.fileName;
				links.push(link);
			});
			links.forEach(link => link.click());
			this.translateService.get('notice.download.success-message').subscribe(
				translation => {
					this.toastrMessageService.show({
						type: ToastrMessageType.SUCCESS,
						messageHTML: `<i class="ti ti-check me-2 fs-5"></i> ${translation}`
					});
				}
			)
			this.activeModal.close(files);
		});
	}
}

import {Component, Input, OnChanges, OnInit, Self} from '@angular/core';
import {ModularFormsService} from '../../shared/modular-forms/modular-forms.service';
import {FormControl, Validators} from '@angular/forms';
import {User} from '../_model/user';
import {RoleService} from '../../role/role.service';
import {CustomerService} from '../../customer/customer.service';
import {map, Observable, shareReplay} from 'rxjs';
import {SelectOption} from '../../shared/modular-forms/_model/select-option';
import {mapCustomers, mapProjects, mapRoles} from '../../shared/modular-forms/_model/select-option.factory';
import {ProjectService} from '../../project/_service/project.service';

@Component({
	selector: 'app-user-form',
	templateUrl: './user-form.component.html',
	providers: [ModularFormsService],
	exportAs: 'userForm'
})
export class UserFormComponent implements OnInit, OnChanges {

	@Input()
	public user: Partial<User>;

	@Input()
	public readonly = false;

	roles$: Observable<SelectOption[]>;
	customers$: Observable<SelectOption[]>;
	projects$: Observable<SelectOption[]>;
	types: SelectOption[] = [
		{value: null, label: 'user.form.type.options.no-type', translate: true, id: '0'},
		{value: 'BUYER', label: 'user.form.type.options.buyer', translate: true, id: '1'},
		{value: 'OPERATOR', label: 'user.form.type.options.operator', translate: true, id: '2'}
	]

	constructor(@Self() protected formService: ModularFormsService,
				private roleService: RoleService,
				private customerService: CustomerService,
				private projectService: ProjectService) {

		this.roles$ = this.roleService.findAll().pipe(
			mapRoles(),
			shareReplay()
		);
		this.customers$ = this.customerService.findAll().pipe(
			mapCustomers(),
			shareReplay()
		);
		this.projects$ = this.projectService.findAll().pipe(
			mapProjects(),
			shareReplay()
		);

		this.formService.withI18nRoot('user.form');
		const form = this.formService.form;

		form.addControl('userName', new FormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern('[a-zA-Z0-9-_.]*')]));
		form.addControl('firstName', new FormControl('', [Validators.required, Validators.maxLength(50)]));
		form.addControl('lastName', new FormControl('', [Validators.required, Validators.maxLength(50)]));
		form.addControl('email', new FormControl('', [Validators.required, Validators.maxLength(100), Validators.email]));
		form.addControl('role', new FormControl(null, [Validators.required]));
		form.addControl('customer', new FormControl(null));
		form.addControl('type', new FormControl(null));
		form.addControl('projects', new FormControl(null));

		form.valueChanges.subscribe(() => {
			this.updateOptionalFields();
		})
	}

	ngOnInit(): void {
		this.formService.form.patchValue(this.user);
		if (this.isEditing()) {
			this.formService.form.get('userName').disable();
			this.formService.form.get('email').disable();
			this.setSelectedProjects();
		}
	}

	ngOnChanges(): void {
		if (this.readonly) {
			this.formService.form.disable();
		} else {
			this.formService.form.enable();
		}
	}

	updateOptionalFields(): void {
		if (this.requireOptionalFields()) {
			this.formService.form.controls['customer'].addValidators(Validators.required);
		} else {
			this.formService.form.controls['customer'].clearValidators();
		}
	}

	requireOptionalFields(): boolean {
		return this.formService.form.controls['role'].value?.isInternal === false;
	}

	isProductionRoleSelected(): boolean {
		return this.formService.form.controls['role'].value?.isProductionRole === true;
	}

	private isEditing(): boolean {
		return this.user !== undefined;
	}

	private setSelectedProjects(): void {
		const projectUuids = this.user.projects.map(project => project.uuid);
		this.projects$.pipe(
			map(projects => projects.filter(project => projectUuids.indexOf(project.id) >= 0))
		)
			.subscribe(selectedProjects => this.formService.getControl('projects').patchValue(selectedProjects));
	}

	public getUser(): User {
		const user = this.formService.form.value;

		if (user.role.isInternal) {
			user.customer = null;
			user.type = null;
		}

		user.projectUuids = user.projects?.map((p: SelectOption) => p.id);
		if (!user.role.isProductionRole) {
			user.projects = null;
		}

		return user;
	}

	public isInvalid(): boolean {
		return this.formService.form.invalid;
	}

}

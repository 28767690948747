
<form [formGroup]="formsService.form" (ngSubmit)="this.submit()" class="row flex-xxl-row-reverse">

	<div class="col-12 col-xxl-6">
		<app-modular-form-control-files name="actualFiles"/>

		<app-modular-form-dynamic-array arrayControlName="actualFiles" name="files"
										[formGroupFactory]="fileMetaGroupFactory()">
			<ng-template let-index>
				<app-modular-form-control-input [name]="'files.' + index + '.fileName'" i18nPrefix="notice.upload.form"/>

				<app-modular-form-control-input [name]="'files.' + index + '.hash'" i18nPrefix="notice.upload.form"/>
			</ng-template>
		</app-modular-form-dynamic-array>
	</div>

	<div class="col-12 col-xxl-6">
		<app-modular-form-control-input name="reference" layout="flex"/>

		<app-modular-form-control-input name="title" layout="flex"/>

		<app-modular-form-control-radiogroup name="makeAvailableFor" layout="flex" [options]="makeAvailableFor"/>

		<ng-container *ngIf="getControlValue('makeAvailableFor') === 'CUSTOMERS'">
			<app-modular-form-control-multiselect name="availableCustomerUuids"
												  layout="flex" [options]="customers$ | async"/>
		</ng-container>
		<ng-container *ngIf="getControlValue('makeAvailableFor') === 'PROGRAMS'">
			<app-modular-form-control-multiselect name="availableProgramUuids" layout="flex" [options]="programs$ | async" />
		</ng-container>

		<app-modular-form-control-checkbox name="notifyCustomers" layout="flex"/>

		<app-modular-form-control-select name="level" layout="flex" [options]="levels"/>

		<app-modular-form-control-select *ngIf="getControlValue('level') === 'DOCUMENT'" name="folderUuid" layout="flex"
                                         [options]="folders$ | async"/>

		<ng-container *ngIf="getControlValue('level') ?? undefined && getControlValue('level') !== 'DOCUMENT'">
			<app-modular-form-control-date name="effectiveDate" layout="flex"/>

			<app-modular-form-control-date name="endValidityDate" layout="flex"/>
		</ng-container>
	</div>

	<div class="d-flex flex-row justify-content-end">
		<input id='upload-button' type="submit" class="btn btn-prima§ry"
			   [value]="'notice.upload.form.upload' | translate"
			   [disabled]="formsService.form.invalid"/>
	</div>

</form>

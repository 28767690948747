import {Injectable} from '@angular/core';
import {FilterCriteria} from '../model/filter-criteria';
import {DropdownData} from './dropdown-data';

@Injectable()
export class DropdownDataFactory {

	createDropdownData(criteria: FilterCriteria): DropdownData {
		return {
			label: criteria.property,
			value: criteria.property,
			translate: criteria.translatedOptions,
			subItems: criteria.filterOptions.map(option => {
				return {
					label: option.translationKey,
					value: option.value
				}
			})
		};
	}
}

<label class="form-label big">{{ i18nLabel() | translate }}</label><br>
<span class="empty-state-text subtitle mb-3" *ngIf="!getArticleCount()">
	{{ i18nPlaceholder() | translate }}
</span>

<app-modular-form-control-multiselect name="selectedArticles"
									  [options]="articles"></app-modular-form-control-multiselect>

<ng-container *ngFor="let x of getSelectedArticles(); let i = index">
	<div class="d-flex align-items-center mb-3 flex-nowrap gap-big px-3">
		<app-modular-form-control-input [name]="name + '.' + i + '.article'"></app-modular-form-control-input>

		<app-modular-form-control-date [name]="name + '.' + i + '.productionDate'"/>

		<i class="ti ti-trash float-end text-danger me-2 fs-4 pointer" *ngIf="!formControl.disabled" (click)="removeArticle(x)">
		</i>
	</div>
</ng-container>

import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {finalize, Observable, Subscription} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FolderService} from '../../_service/folder.service';
import {Folder} from '../folder';
import {UpdateForm} from '../../../shared/modal/update.form';

@Component({
	selector: 'app-create-or-update-modal-folder',
	templateUrl: './create-or-update-folder-modal.component.html'
})
export class CreateOrUpdateFolderModalComponent implements OnInit, OnDestroy, UpdateForm<Folder> {
	folderForm: FormGroup;
	submitting = false;
	folder: Folder;
	formGroup: FormGroup;
	private subscription = new Subscription();

	constructor(public activeModal: NgbActiveModal,
				private formBuilder: FormBuilder,
				private folderService: FolderService
	) {
		this.folderForm = formBuilder.group({
			'name': formBuilder.control('', [Validators.required, Validators.maxLength(255)])
		});
	}

	ngOnInit(): void {
		this.formGroup = this.formBuilder.group({});
	}

	setContent(content: Folder): void {
		this.folder = content;
		this.folderForm.controls['name'].setValue(content.name);
	}

	getControlFromGroup(name: string, group: AbstractControl<any, any>): FormControl<any> {
		return (group as FormGroup).controls[name] as FormControl<any>;
	}

	getControl(name: string): FormControl<any> {
		return this.folderForm.controls[name] as FormControl<any>;
	}

	close(): void {
		if (!this.submitting) {
			this.activeModal.dismiss();
		}
	}

	save(): void {
		if (this.submitting) {
			return;
		}
		this.submitting = true;
		this.subscription.add(this.doSave()
			.pipe(
				finalize(() => {
					this.submitting = false;
				})
			).subscribe((folder) => {
				this.activeModal.close(folder);
			})
		);
	}

	doSave(): Observable<Folder> {
		if (!this.folder) {
			return this.folderService.create({
				name: this.folderForm.value.name
			})
		} else {
			return this.folderService.update(
				this.folder.uuid, {
					name: this.folderForm.value.name
				});
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}


import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlightManagementSystemService} from './_service/flight-management-system.service';
import {ModularFormsModule} from '../shared/modular-forms/modular-forms.module';
import {ReactiveFormsModule} from '@angular/forms';
import {FmsUploadFormComponent} from './upload/upload-form/fms-upload-form.component';
import {FmsUploadProgressComponent} from './upload/upload-progress/fms-upload-progress.component';
import {TranslateModule} from '@ngx-translate/core';
import {UploadModule} from '../shared/upload/upload.module';
import {FmsUploadComponent} from './upload/fms-upload.component';

@NgModule({
	declarations: [
		FmsUploadComponent,
		FmsUploadFormComponent,
		FmsUploadProgressComponent
	],
	imports: [
		CommonModule,
		ModularFormsModule,
		ReactiveFormsModule,
		TranslateModule,
		UploadModule
	],
	providers: [
		FlightManagementSystemService
	]
})
export class FlightManagementSytemModule {
}

import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Cycle, InvalidCycleIdentifierError} from 'airac-cc';

export class CustomValidators {
	static validAirac(control: AbstractControl): ValidationErrors | null {
		try {
			if (control.value === 'NA') {
				return null;
			}
			Cycle.fromIdentifier(control.value);
			return null;
		} catch (error) {
			if (error instanceof InvalidCycleIdentifierError) {
				return {invalidAiracFormat: {value: control.value}};
			}
			return null;
		}
	}
}

<form [formGroup]="formService.form">
	<ng-container *ngIf="productionCatalogue && !readonly && oldStatus?.value !== 'REDACTION_IN_PROGRESS'">
		<div class="col px-md-1">
			<div class="alert alert-danger alert-dismissible fade show" role="alert">
				<span [innerHTML]="'production-catalogue.form.not.editable.state.is.not.redaction.in.progress' | translate"></span>
			</div>
		</div>
	</ng-container>

	<div class="d-flex w-75" *ngIf="productionCatalogue && !copy">

		<div class="col px-md-1">
			<app-modular-form-control-select appConfirmationModal
											 [event]="'change'"
											 [danger]="false"
											 [hasFooter]="false"
											 (cancelAction)="cancelStatusUpdate()"
											 [confirmationMessage]="'production-catalogue.status-change.confirmation-message.are-you-sure' | translate"
											 name="status"
											 [options]="productionCatalogueStatuses"></app-modular-form-control-select>
		</div>
		<div class="col px-md-1">
			<app-modular-form-control-input name="approvalDate"></app-modular-form-control-input>
		</div>
	</div>

	<div class="d-flex w-75">
		<div class="col px-md-1">
			<app-modular-form-control-input name="identification"></app-modular-form-control-input>
		</div>
		<div class="col px-md-1">
			<app-modular-form-control-input name="airacCycle"></app-modular-form-control-input>
		</div>
	</div>

	<div class="d-flex w-75">
		<div class="col px-md-1">
			<app-modular-form-control-input name="requesterUser"></app-modular-form-control-input>
		</div>
		<div class="col px-md-1">
			<app-modular-form-control-select name="project"
											 [options]="projects$ | async"></app-modular-form-control-select>
		</div>
	</div>

	<ng-container *ngIf="articles$ | async as articles">
		<app-production-catalogue-article-form name="articles"
											   [articles]="articles"
											   [prodCat]="productionCatalogue"
											   [readonly]="readonly"></app-production-catalogue-article-form>
	</ng-container>

	<ng-container *ngIf="productionCatalogue">
		<app-comment-form name="comments" [productionCatalogue]="productionCatalogue"></app-comment-form>
	</ng-container>

	<app-invalid-feedback></app-invalid-feedback>
</form>

<label class="form-label big">{{ 'production-catalogue.form.comments.label' | translate }}</label><br>
<span class="empty-state-text subtitle mb-3" *ngIf="getControls().length < 1">
	{{ 'production-catalogue.form.comments.placeholder' | translate }}
</span>
<ng-container *ngIf="isAddingComment$ | async">
	<div class="d-inline-block">
		<form [formGroup]="formsService.form">
			<app-modular-form-control-textarea [name]="name + '.' + (getCommentCount() - 1) + '.comment'"
											   layout="flex-inline"/>
		</form>
		<div class="d-flex justify-content-end mt-2 gap-1">
			<button id="cancel-button" type="button" class="btn btn-secondary me-3"
					(click)="cancel()">{{ 'general.cancel' | translate }}
			</button>
			<button id="confirm-button" class="btn" (click)="save()" type="button"
					[disabled]="!isValid(getCommentCount()-1)">{{ 'general.save' | translate }}
			</button>
		</div>
	</div>
</ng-container>

<button *ngIf="(isAddingComment$ | async) === false" type="button" id="add-comment" (click)="addComment()"
		class="btn btn-secondary mb-2">
	<i class="ti ti-plus me-1"></i>
	{{ 'production-catalogue.form.comments.add' | translate }}
</button>

<div *ngFor="let comment of getControls(); let cnt = count; let i = index; last as isLast" id="comments">
	<div *ngIf="!((isAddingComment$ | async) && isLast)">
		<p class="font-weight-normal" id="{{'comment-title-'+i }}">
			{{ getUserName(i) + ' ' + ('production-catalogue.form.comments.added-comment' | translate) }}
			<span class="fw-500">{{ '(#' + (cnt - i) + ') - ' }}</span>
			<span class="text-capitalize">{{ comment.get('date').value }}</span>
		</p>
		<span class="small-comment w-50 font-weight-light d-block">{{ comment.get('comment').value }}</span>
		<hr [style.width.px]="735">
	</div>
</div>


import {ModularFormsService} from '../../../shared/modular-forms/modular-forms.service';

export type UploadStep = 'INPUT' | 'PROCESSING';

export abstract class UploadBaseComponent {

	step: UploadStep = 'INPUT';

	protected constructor(private formService: ModularFormsService) {
		this.formService.withI18nRoot('db.upload.form');
	}

	public submit(): void {
		this.step = 'PROCESSING';
	}

	public reset(): void {
		this.step = 'INPUT';
		this.formService.form.reset();
	}

}

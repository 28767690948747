import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CreateProductionCatalogueRequest} from '../_model/create-production-catalogue-request';
import {UpdateProductionCatalogueRequest} from '../_model/update-production-catalogue-request';
import {ProductionCatalogue} from '../_model/production-catalogue';
import {CreateCommentRequestData} from '../_model/create-comment.request';
import {FormGroup} from '@angular/forms';

@Component({
	selector: 'app-production-catalogue-form-page',
	templateUrl: './production-catalogue-form-page.component.html'
})
export class ProductionCatalogueFormPageComponent {

	@Input() productionCatalogue: ProductionCatalogue;
	@Input() copy = false;

	@Output()
	public closePage: EventEmitter<void> = new EventEmitter();

	@Output()
	public createCatalogue: EventEmitter<CreateProductionCatalogueRequest> = new EventEmitter();

	@Output()
	public updateCatalogue: EventEmitter<UpdateProductionCatalogueRequest> = new EventEmitter();

	close(): void {
		this.closePage.emit();
	}

	submitForm(form: FormGroup): void {
		if (this.productionCatalogue && !this.copy) {
			this.updateCatalogue.emit(this.getUpdateProductionCatalogueRequest(form));
		} else {
			this.createCatalogue.emit(this.getCreateProductionCatalogueRequest(form));
		}
	}

	get i18nSubmit(): string {
		if (this.copy) {
			return 'general.copy';
		}
		return this.productionCatalogue ? 'general.save' : 'general.create';
	}

	getCreateProductionCatalogueRequest(form: FormGroup): CreateProductionCatalogueRequest {
		const comments = form.get('comments')?.value as CreateCommentRequestData[];
		comments?.forEach((c: any) => {
			c.userUuid = c.user.uuid;
			delete c.user
		});
		const articles = form.get('articles').value;

		articles.forEach((article: any) => {
			if (typeof article.productionDate !== 'string') {
				const day = String(article.productionDate['day']).padStart(2, '0');
				const month = String(article.productionDate['month']).padStart(2, '0');
				const year = article.productionDate['year'];
				article.productionDate = year + '-' + month + '-' + day;
			}
		});

		return {
			airacCycle: form.get('airacCycle').value,
			articleProductions: articles,
			identification: form.get('identification').value,
			projectUuid: form.get('project').value,
			requesterUserUuid: form.get('requesterUserUuid').value,
			comments: comments
		};
	}

	getUpdateProductionCatalogueRequest(form: FormGroup): UpdateProductionCatalogueRequest {
		let comments = form.get('comments').value as CreateCommentRequestData[];
		comments = comments.filter((c: any) => !!c.new);
		comments.forEach((c: any) => {
			if (!c.userUuid) {
				c.userUuid = c.user.uuid;
			}
			delete c.user
		});

		return {
			uuid: this.productionCatalogue.uuid,
			identification: form.get('identification').value,
			status: form.get('status').value,
			airacCycle: form.get('airacCycle').value,
			projectUuid: form.get('project').value,
			articleProductions: form.get('articles').value,
			comments: comments
		};
	}

}

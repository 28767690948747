import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ArticleDescriptor} from '../_model/article-descriptor';
import {Page} from '../../shared/pagination/page';
import {ArticleOverview} from '../_model/article-overview';
import {CreateOrUpdateArticleRequest} from '../_model/create-or-update-article-request';
import {ContentArray} from '../../shared/model/content-array';
import {FilterCriteria} from '../../shared/model/filter-criteria';
import {Article} from '../_model/article';

@Injectable({
	providedIn: 'root'
})
export class ArticleService {

	readonly baseUrl = environment.backend_url + '/article';

	constructor(private http: HttpClient) {
	}

	findAll(): Observable<Array<ArticleDescriptor>> {
		return this.http.get<Array<ArticleDescriptor>>(this.baseUrl);
	}

	search(pageNumber: number, contentSearchCriteria: ContentArray, searchValue: string): Observable<Page<ArticleOverview>> {
		let params = new HttpParams();
		params = params.append('page', pageNumber);
		if (searchValue) {
			params = params.append('search.*', searchValue);
		}
		Object.keys(contentSearchCriteria)
			.forEach(key => contentSearchCriteria[key]
				.forEach(value => params = params.append('filter.' + key, value)));
		return this.http.get<Page<ArticleOverview>>(this.baseUrl + '/search', {params: params});
	}

	delete(uuid: string): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${uuid}`);
	}

	update(uuid: string, request: CreateOrUpdateArticleRequest): Observable<void> {
		return this.http.put<void>(`${this.baseUrl}/${uuid}`, request);
	}

	create(request: CreateOrUpdateArticleRequest): Observable<void> {
		return this.http.post<void>(this.baseUrl, request);
	}

	getNrOfArticlesWithMissingProperties(): Observable<number> {
		return this.http.get<number>(this.baseUrl + '/count-articles-with-missing-properties');
	}

	export(contentSearchCriteria: ContentArray, searchValue: string): Observable<HttpResponse<Blob>> {
		let params = new HttpParams();
		if (searchValue) {
			params = params.append('search.*', searchValue)
		}
		Object.keys(contentSearchCriteria)
			.forEach(key => contentSearchCriteria[key]
				.forEach(value => params = params.append('filter.' + key, value)));
		return this.http.get(this.baseUrl + '/export', {observe: 'response', responseType: 'blob' , params});
	}

	getFilterCriteria(): Observable<FilterCriteria[]> {
		return this.http.get<FilterCriteria[]>(`${this.baseUrl}/filtercriteria`);
	}

	getArticle(uuid: string): Observable<Article> {
		return this.http.get<Article>(`${this.baseUrl}/${uuid}`);
	}
}

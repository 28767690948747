import {Component, EventEmitter, OnDestroy, Output} from '@angular/core';
import {ModularFormsService} from '../../../shared/modular-forms/modular-forms.service';
import {AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {BaseUploadComponent} from '../../../shared/upload/base-upload.component';
import {SelectOption} from '../../../shared/modular-forms/_model/select-option';
import {map, Observable, Subscription} from 'rxjs';
import {FolderService} from '../../_service/folder.service';
import {ProgramService} from '../../_service/program.service';
import {CustomerService} from '../../../customer/customer.service';

@Component({
	selector: 'app-upload-notice-form',
	templateUrl: './upload-notice-form.component.html'
})
export class UploadNoticeFormComponent implements OnDestroy {

	@Output() public submitForm = new EventEmitter();

	protected readonly levels: SelectOption[] = [
		{id: 'DOCUMENT', value: 'DOCUMENT', label: 'notice.upload.form.level.options.document', translate: true},
		{
			id: 'INFORMATION',
			value: 'INFORMATION',
			label: 'notice.upload.form.level.options.information',
			translate: true
		},
		{id: 'SAFETY', value: 'SAFETY', label: 'notice.upload.form.level.options.safety', translate: true},
		{id: 'NON_SAFETY', value: 'NON_SAFETY', label: 'notice.upload.form.level.options.non-safety', translate: true}
	];

	makeAvailableFor: SelectOption[] = [
		{
			id: 'ALL_CUSTOMERS',
			value: 'ALL_CUSTOMERS',
			label: 'notice.upload.form.makeAvailableFor.options.all_customers',
			translate: true
		},
		{
			id: 'CUSTOMERS',
			value: 'CUSTOMERS',
			label: 'notice.upload.form.makeAvailableFor.options.customers',
			translate: true
		},
		{
			id: 'PROGRAMS',
			value: 'PROGRAMS',
			label: 'notice.upload.form.makeAvailableFor.options.programs',
			translate: true
		}
	]

	folders$: Observable<SelectOption[]>;
	programs$: Observable<SelectOption[]>;
	customers$: Observable<SelectOption[]>;

	private subscription = new Subscription();

	constructor(protected formsService: ModularFormsService,
				folderService: FolderService,
				programService: ProgramService,
				customerService: CustomerService) {

		this.folders$ = folderService.findAll()
			.pipe(map(folders => folders.map(folder => ({
				id: folder.uuid,
				value: folder.uuid,
				label: folder.name
			}))));

		this.programs$ = programService.findAll()
			.pipe(map(programs => programs.map(program => ({
				id: program.uuid,
				value: program.uuid,
				label: program.name
			}))));

		this.customers$ = customerService.findAll()
			.pipe(map(customers => customers.map(customer => ({
				id: customer.uuid,
				value: customer.uuid,
				label: customer.name
			}))));

		formsService.withI18nRoot('notice.upload.form');

		const form = formsService.form;
		form.addControl('reference', new FormControl('', [Validators.required, Validators.maxLength(20)]));
		form.addControl('title', new FormControl('', [Validators.required, Validators.maxLength(255)]));

		form.addControl('makeAvailableFor', new FormControl('', [Validators.required]));
		form.addControl('availableCustomerUuids', new FormControl({ value: [], disabled: true}, [this.minLengthArray(1)]));
		form.addControl('availableProgramUuids', new FormControl({ value: [], disabled: true}, [this.minLengthArray(1)]));
		form.addControl('allCustomers', new FormControl(false));
		form.addControl('notifyCustomers', new FormControl(false));

		form.addControl('level', new FormControl(null, [Validators.required]));
		form.addControl('folderUuid', new FormControl({ value: null, disabled: true}, [Validators.required]));
		form.addControl('effectiveDate', new FormControl({ value: null, disabled: true}, [Validators.required, Validators.pattern(BaseUploadComponent.DATE_REGEX_PATTERN)]));
		form.addControl('endValidityDate', new FormControl({ value: null, disabled: true}, [Validators.required, Validators.pattern(BaseUploadComponent.DATE_REGEX_PATTERN)]));

		form.addControl('actualFiles', new FormControl([], [this.minLengthArray(1)]));
		form.addControl('files', new FormArray([]));

		this.subscription.add(form.get('level').valueChanges.subscribe((value) => {
			const form = this.formsService.form;
			form.get('folderUuid').disable();
			form.get('effectiveDate').disable();
			form.get('endValidityDate').disable();

			if (value === 'DOCUMENT') {
				form.get('folderUuid').enable();
			} else {
				form.get('effectiveDate').enable();
				form.get('endValidityDate').enable();
			}

			form.updateValueAndValidity();
		}));

		this.subscription.add(form.get('makeAvailableFor').valueChanges.subscribe(value => {
			form.get('availableCustomerUuids').disable();
			form.get('availableProgramUuids').disable();

			if (value == 'CUSTOMERS') {
				form.get('availableCustomerUuids').enable();
			} else if (value == 'PROGRAMS'){
				form.get('availableProgramUuids').enable();
			}

			form.updateValueAndValidity();
		}))
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	minLengthArray(minLength: number): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			if (Array.isArray(control.value) && control.value.length < minLength) {
				return {'required': true};
			}
			return null;
		};
	}

	getControlValue(name: string): any {
		return this.formsService.form.get(name).value;
	}

	fileMetaGroupFactory(): (file: File) => FormGroup {
		return (file: File) => {
			const fileNameControl = new FormControl(file.name, [Validators.required]);
			fileNameControl.disable();

			return new FormGroup({
				fileName: fileNameControl,
				hash: new FormControl('', [Validators.required, Validators.maxLength(255)])
			});
		}
	}

	submit(): void {
		this.submitForm.emit();
	}

}
